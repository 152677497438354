.app-status {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    color: $c-dark-gray;
    font-weight: $text-weight-normal;

    &--large {
        font-size: $text-size-body;
        height: 35px;
        width: 124px;
    }

    &--small {
        font-size: $text-size-s;
        width: 97px;
        height: 28px;
    }

    &--green {
        background-color: $c-lightest-green;
    }

    &--red {
        background-color: $c-lightest-red;
    }

    &--blue {
        background-color: $c-lightest-blue;
    }

    &--orange {
        background-color: $c-lightest-orange;
    }

    &__confirmed {
        color: $c-white;
        background-color: $c-blue;
    }
}
