.page-claim-summary {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__button-row {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    &__new-button {
        width: 125px;
        height: 40px;
    }

    &__calendar-icon {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%);
        height: 16px;
        width: 16px;
    }

    &__user {
        &-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $c-dark-gray;
            margin-bottom: 30px;

            &--left {
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }
        }
        &-id {
            border-left: 1px solid $c-line-gray;
            padding-left: 8px;
            margin-left: 8px;
        }
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    .react-table {
        &__claim-type {
            &-header {
                padding-left: 24px;
            }
            &-row {
                padding-left: 24px;
            }
        }

        &__table-data {
            margin-left: 30px;
        }

        &__amount-row {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-right: 120px;
        }
    }

    &__table-title {
        margin-top: 32px;
        margin-bottom: 16px;
        color: $c-dark-gray;
        font-weight: $text-weight-medium;
        font-size: $text-size-body;
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
