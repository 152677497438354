.app-mega-dropdown {
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        height: 56px;
        align-items: center;
        padding-left: 16px;
        padding-right: 18px;

        &-left {
            color: $c-blue;
            font-weight: $text-weight-bold;
            font-size: $text-size-m;
        }

        &-right {
            display: flex;
            flex-direction: row;
        }
    }

    &__toggle-wrapper {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__approve-all {
        color: $c-dark-gray;
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
        align-self: center;
        margin-right: 18.25px;
    }

    &__arrow {
        width: 20px;

        &--rotate {
            transform: rotate(180deg);
        }
    }

    &__contents {
        padding-bottom: 20px;
        margin-left: 16px;
        margin-right: 56px;

        &--show {
            display: flex;
        }

        &--hide {
            display: none;
        }
    }
}
