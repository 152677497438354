.page-employee-payslip {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    &__user {
        &-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $c-dark-gray;
            margin-bottom: 30px;

            &--left {
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }
        }
        &-id {
            border-left: 1px solid $c-line-gray;
            padding-left: 8px;
            margin-left: 8px;
        }
    }

    .react-table {
        &__header-checkbox-wrapper {
            margin-bottom: 30px;
            margin-left: 25px;
        }
        &__row-checkbox-wrapper {
            margin-top: -22px;
            margin-left: 26px;
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }

            &--cursor-default {
                &:hover {
                    cursor: default;
                }
            }
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__modal-attachment {
            width: 80vh;
            height: 95vh;
        }

        &__attachment {
            margin-left: 18px;
            &-icon {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__download-icon {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__table-header-button-wrapper {
        width: 93px;
        height: 32px;
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
