.page-coaching-upcoming {
    &__column-modal-left {
        .column-modal-left {
            &__checkbox-wrapper {
                display: flex;
                flex-direction: row;
                position: relative;
                height: 20px;
                margin-top: 7px;
                margin-bottom: 24px;
            }

            &__checkbox-label {
                color: $c-dark-gray;
                font-weight: $text-weight-normal;
                font-size: $text-size-s;
                position: absolute;
                top: -1px;
                left: 25px;
            }

            &__input-wrapper {
                margin-bottom: 16px;
            }
        }
    }

    &__column-modal-right {
        .column-modal-right {
            &__cancel-wrapper {
                margin-top: 8px;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 22px;
                margin-right: 16px;
            }

            &__cancel {
                color: $c-red;
                font-size: $text-size-s;
                font-weight: $text-weight-normal;
                background-color: inherit;
                border: none;
                &:active {
                    filter: brightness(1.2);
                }
            }

            &__input-wrapper {
                margin-bottom: 16px;
            }

            &__time-wrapper {
                margin-bottom: 16px;
            }
        }
    }

    &__column-modal-bottom {
        display: flex;
        flex-direction: row;
        margin-top: 24px;

        .column-modal-bottom {
            &__modal-input-button-row {
                display: flex;
                flex-direction: row;
            }

            &__modal-input-button {
                height: 43px;
                width: 144px;
                margin-left: 16px;
            }
        }
    }

    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__button-wrapper {
        height: 38px;
        width: 230px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    .react-table {
        &__attached-benefit {
            &-header {
                margin-left: 24px;
            }

            &-data {
                margin-left: 24px;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__status {
            &-header {
                &:hover {
                    cursor: pointer;
                    .react-table__icon-filter {
                        display: inline-block;
                    }
                }
            }

            &-data {
                border-radius: 999px;
                height: 28px;
                width: 97px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &--green {
                background-color: $c-lightest-green;
            }
            &--orange {
                background-color: $c-lightest-orange;
            }
            &--red {
                background-color: $c-lightest-red;
            }
        }

        &__icon-filter {
            margin-left: 12px;
            display: none;
            &--active {
                filter: brightness(0.5);
                display: inline-block;
            }
        }

        &__dropdown {
            position: absolute;
            margin-left: 45px;
            margin-top: 5px;
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__search-content {
        border: 1px solid $c-blue-gray;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }

    &__input {
        width: 624px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &-wrapper {
            width: 304px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    &__advance-button {
        &-row {
            display: flex;
            flex-direction: row;
        }
        &-wrapper {
            height: 32px;
            width: 66px;
            margin-left: 8px;
        }
    }
}
