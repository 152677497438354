.new-leave {

    &__contents {
        width: 624px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }

    &__input-wrapper {
        width: 304px;
        min-height: 38px;
        margin-bottom: 16px;
    }

    &__button-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 8px;
    }

    &__button-wrapper {
        height: 43px;
        width: 144px;
        margin-left: 16px;
    }
}