.app-modal {
    display: inline-flex;
    margin: auto;

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        z-index: 999;

        &-open {
            animation: fadeIn 0.2s ease forwards;
        }
        &-close {
            animation: fadeOut 0.2s ease forwards;
        }
    }

    &__open {
        animation: fadeIn 0.2s ease forwards;
    }

    &__close {
        animation: fadeOut 0.2s ease forwards;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        &:active {
            outline: none;
            text-decoration: none;
        }

        &:focus {
            outline: none;
            text-decoration: none;
        }
    }

    &__header {
        height: 53px;
        background-color: $c-blue-gray-bright;
        padding: 16px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        color: $c-dark-gray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $text-weight-normal;

        &-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &-status {
            margin-right: 18px;
            width: 123px;
            height: 35px;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;

            &--green {
                background-color: $c-lightest-green;
            }

            &--red {
                background-color: $c-lightest-red;
            }

            &--orange {
                background-color: $c-lightest-orange;
            }
        }
    }

    &__close-icon {
        height: 12px;
        width: 12px;
        margin-left: 20px;
        &:hover {
            cursor: pointer;
        }
    }

    &__body {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: $c-white;

        &-padding {
            padding: 16px 16px 24px 16px;
        }
    }
}

.ReactModal__Content {
    &:focus {
        outline: none;
    }
}
