.page-user-management {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__action-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__buttons-wrapper {
        display: flex;
        flex-direction: row;
        height: 38px;
    }

    &__export-wrapper {
        width: 97px;
    }

    &__new-employee-wrapper {
        margin-left: 8px;
        width: 150px;
    }

    &__bulk-upload-wrapper {
        margin-left: 8px;
        width: 130px;
    }

    .react-table {

        &__employee-username-column {
            margin-left: 25px;
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__header-checkbox-wrapper {
            margin-bottom: 30px;
            margin-left: 25px;
        }

        &__row-checkbox-wrapper {
            margin-top: -22px;
            margin-left: 26px;
        }

        &__email {
            display: flex;
            flex: 1;
            text-overflow: ellipsis !important;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 10px;
        }
        
        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }
    }

    &__paginate-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    &__bulk-upload {
        .bulk-upload {
            &__upload-contents {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px;
                margin-bottom: 12px;
                margin-left: 80px;
                margin-right: 80px;
            }

            &__input {
                position: absolute;
                top: -999px;
                left: -999px;
                width: 0px;
                height: 0px;
                opacity: 0;
            }

            &__upload-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: white;
                width: 144px;
                height: 160px;
                border: 1px dashed $c-light-blue;
                border-radius: 6px;
                justify-content: space-evenly;
            }

            &__upload-container--error {
                border: 1px dashed $c-red;
            }

            &__upload-title {
                color: $c-dark-gray;
                font-size: $text-size-s;
                font-weight: $text-weight-normal;
            }

            &__upload-subtitle {
                color: $c-dark-gray;
                font-size: $text-size-s;
                font-weight: $text-weight-medium;
            }

            &__button-wrapper {
                height: 32px;
                width: 102px;
                // pointer-events: none;
            }

            &__error-message {
                color: $c-red;
                font-size: $text-size-xxs;
                font-weight: $text-weight-medium;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__template-contents {
                // background-color: blue;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-top: 1px solid $c-line-gray;
                margin-top: 12px;
            }

            &__template-detail {
                margin-top: 8px;
                font-size: $text-size-xs;
                font-weight: $text-weight-medium;
            }

            &__template-link {
                font-size: $text-size-xs;
                font-weight: $text-weight-normal;
                color: $c-blue;
                text-decoration: underline;
                background-color: inherit;
                border: none;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    &__bulk-uploading {
        margin-top: 5px;
        width: 272px;

        .bulk-uploading {
            &__subtitle {
                color: $c-blue-gray-darkest;
                font-weight: $text-weight-medium;
                font-size: $text-size-xs;
                margin-bottom: 5px;
            }

            &__body {
                display: flex;
                flex-direction: row;
                margin-top: 5px;
            }

            &__contents {
                display: flex;
                flex-direction: row;
                flex: 1;
                justify-content: space-between;
                margin-left: 7px;
            }

            &__file {
                &-details {
                    display: flex;
                    flex-direction: column;
                }

                &-title {
                    color: $c-blue-gray-darkest;
                    font-size: $text-size-xxs;
                }

                &-size {
                    color: $c-lightest-gray;
                    font-size: $text-size-xxxs;
                    font-weight: $text-weight-medium;
                }
            }

            &__icon-button {
                display: flex;
                flex-direction: row;
            }

            &__icon-close {
                color: $c-red;
                margin-left: 8px;
                &:hover {
                    cursor: pointer;
                }
            }

            &__button-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 26px;
            }

            &__button-wrapper {
                width: 80px;
                height: 32px;
                margin-left: 8px;
            }

            &__error-message {
                color: $c-red;
                font-size: $text-size-xxs;
                font-weight: $text-weight-medium;
                // margin-top: 15px;
                // margin-bottom: -15px;
            }
        }
    }
}
