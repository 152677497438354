/* -- Color -- */
$c-body: #f3f3f6;
$c-table-header: #f4f6f9;
$c-white: #ffffff;
$c-black: #000000;
$c-gray: #f6f6f6;
$c-dark-gray: #444444;
$c-darker-gray: #666666;
$c-line-gray: #e6e6e6;
$c-lightest-gray: #999999;
$c-dark-blue-gray: #a2b1c5;
$c-dark-blue: #0c4992;
$c-blue-gray: #c9d2de;
$c-blue-gray-bright: #eef1f5;
$c-blue-gray-darkest: #505e70;
$c-blue: #1a6ed3;
$c-light-blue: #4d9af6;
$c-lightest-blue: #ecf5ff;
$c-blue-white: #f7fafe;
$c-light-green: #85d19f;
$c-lightest-green: #dcf4e4;
$c-red: #dc4c4c;
$c-lightest-red: #ffdbdb;
$c-amber: #facb57;
$c-lightest-orange: #ffe8d7;

/* -- Font size -- */
$text-size-xxxxl: 48px;
$text-size-xxxl: 36px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-xml: 24px;
$text-size-l: 22px;
$-text-size-ml: 20px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;
$text-size-xxxs: 8px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-extra-bold: 800;
$text-weight-bold: 700;
$text-weight-semi-bold: 600;
$text-weight-medium: 500;
$text-weight-normal: 400;
$text-weight-light: 300;
$text-weight-extra-light: 200;
$text-weight-thin: 100;

/* -- Media query -- */
$scr-xlg: "1600px";
$scr-lg: "1200px";
$scr-md: "1024px";
$scr-sm: "768px";
$scr-xs: "679px";
