.app-breadcrumb {
    display: flex;
    font-weight: $text-weight-medium;
    font-size: $text-size-body;

    &__label {
        color: $c-blue-gray-darkest;
        &:hover {
            cursor: pointer;
        }

        &:last-child {
            color: $c-blue;
            font-weight: $text-weight-medium;
        }
    }

    &__arrow {
        height: 10px;
        margin-left: 11px;
        margin-right: 11px;
    }
}
