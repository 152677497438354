.app-attachment-image {
    display: flex;
    position: relative;
    width: 20px;
    justify-content: center;
    align-items: center;

    &--hover-cursor {
        cursor: pointer;
    }

    &__file-type {
        position: absolute;
        font-size: 6px;
        width: 90%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}