.page-leave-history {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__button-row {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    &__new-button {
        width: 125px;
        height: 40px;
    }
    
    &__calendar-icon {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%);
        height: 16px;
        width: 16px;
    }

    &__user {
        &-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $c-dark-gray;
            margin-bottom: 30px;

            &--left {
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }
        }
        &-id {
            border-left: 1px solid $c-line-gray;
            padding-left: 8px;
            margin-left: 8px;
        }
        &-status {
            width: 97px;
            height: 28px;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;
            &--green {
                background-color: $c-lightest-green;
            }
            &--red {
                background-color: $c-lightest-red;
            }
        }
    }

    .react-table {
        &__leave-type {
            &-header {
                padding-left: 24px;
            }
            &-row {
                padding-left: 24px;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__days {
            &-row {
                padding-left: 10px;
            }
        }

        &__status {
            &-header {
                &:hover {
                    cursor: pointer;
                    .react-table__icon-filter {
                        display: inline-block;
                    }
                }
            }
        }

        &__icon {
            &-arrow {
                margin-left: 12px;
                height: 8px;
            }
            &-filter {
                margin-left: 12px;
                display: none;
                &--active {
                    filter: brightness(0.5);
                    display: inline-block;
                }
            }
        }

        &__dropdown {
            position: absolute;
            margin-left: 45px;
            margin-top: 5px;
        }
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__modal-input-wrapper {
        margin-bottom: 16px;
    }
}
