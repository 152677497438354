.app-header-bar {
    height: 72px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    &__contents {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }

    &__icon-wrapper {
        position: relative;
        padding: 24px;
        &:hover {
            cursor: pointer;
        }
    }

    &__icon {
        height: 24px;
        width: 21px;
    }

    &__icon-number {
        font-weight: $text-weight-bold;
        font-size: $text-size-xxs;
        background-color: $c-amber;
        border-radius: 999px;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 23px;
        right: 18px;
        padding-top: 2px;
    }

    &__separator {
        border-right: 1px solid $c-blue-gray;
        height: 32px;
    }

    &__dropdown {
        margin-right: 64px;
        padding: 0px !important;

        &-icon {
            margin-left: 10px;
            height: 5.5px;
        }
    }

    .btn-secondary:focus {
        box-shadow: none !important;
    }

    &__change-password-modal {

        width: 304px;

        .change-password-modal {

            &__input-wrapper-1 {
                margin-bottom: 16px;
            }

            &__input-wrapper-2 {
                margin-bottom: 8px;
            }

            &__input-wrapper-3 {
                margin-top: 7px;
            }

            &__requirement-title {
                font-size: $text-size-s;
                font-weight: $text-weight-medium;
                color: $c-lightest-gray;
                margin-bottom: 9px;
            }

            &__contents {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }

            &__requirement-details {
                color: $c-lightest-gray;
                font-size: $text-size-s;
                font-weight: $text-weight-normal;
                margin-left: 11px;
            }

            &__gray {
                color: $c-blue-gray;
            }
        
            &__green {
                color: $c-light-green;
            }
        
            &__red {
                color: $c-red;
            }

            &__buttons-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 24px;
            }

            &__button-wrapper {
                width: 144px;
                height: 43px;
            }
        }
    }
}
