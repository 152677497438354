.page-new-password {
    &__logo {
        height: 40px;
        width: 90px;
        margin-bottom: 24px;
    }

    &__title {
        font-size: $text-size-xxxl;
        font-weight: $text-weight-normal;
        margin-bottom: 8px;
    }

    &__subtitle {
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
        color: $c-lightest-gray;
        margin-bottom: 24px;
    }

    &__input-new-password {
        margin-bottom: 8px;
        width: 304px;
    }

    &__input-confirm-password {
        margin-bottom: 24px;
        margin-top: 10px;
        width: 304px;
    }

    &__requirement-title {
        font-size: $text-size-s;
        font-weight: $text-weight-medium;
        color: $c-lightest-gray;
        margin-bottom: 9px;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 43px;
    }

    &__button {
        width: 48%;
    }

    &__contents {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }

    &__requirement-details {
        color: $c-lightest-gray;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        margin-left: 11px;
    }

    &__gray {
        color: $c-blue-gray;
    }

    &__green {
        color: $c-light-green;
    }

    &__red {
        color: $c-red;
    }
}
