.page-pending-leave {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }
    &__user {
        &-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $c-dark-gray;
            margin-bottom: 30px;

            &--left {
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }
        }
        &-id {
            border-left: 1px solid $c-line-gray;
            padding-left: 8px;
            margin-left: 8px;
        }
        &-status {
            width: 97px;
            height: 28px;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;
            &--green {
                background-color: $c-lightest-green;
            }
            &--red {
                background-color: $c-lightest-red;
            }
        }
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__table-wrapper {
        margin-top: 24px;

        .react-table {
            &__header-checkbox-wrapper {
                margin-bottom: 30px;
                margin-left: 25px;
            }
            &__row-checkbox-wrapper {
                margin-top: -22px;
                margin-left: 26px;
            }
            &__leave-type {
                &-header {
                    margin-left: 25px;
                }
                &-data {
                    margin-left: 25px;
                }
            }
            &__days-data {
                margin-left: 10px;
            }
        }
    }

    &__table-header {
        &-row {
            display: flex;
            flex-direction: row;
        }

        &-button-wrapper {
            height: 32px;
            width: 122px;
            margin-right: 8px;
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__modal-input-wrapper {
        margin-bottom: 16px;
    }

    &__modal {
        width: 304px;

        &-subtitle {
            color: $c-lightest-gray;
            font-weight: $text-weight-medium;
            font-size: $text-size-xs;
        }

        &-input {
            margin-top: 16px;
        }

        &-button-row {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
        }

        &-button {
            height: 43px;
            width: 144px;
        }
    }

    &__modal-input-button-row {
        display: flex;
        flex-direction: row;
    }

    &__modal-input-button {
        height: 43px;
        width: 144px;
        margin-left: 16px;
    }
}
