.page-employee-details {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__header {
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__profile-image {
        height: 180px;
        width: 180px;
        border-radius: 999px;
    }

    &__status-wrapper {
        width: 124px;
        height: 35px;
        border-radius: 999px;
        color: $c-white;
        background-color: $c-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }

    &__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__column-title {
        font-weight: $text-weight-medium;
        font-size: $text-size-m;
        color: $c-dark-gray;
    }

    &__divider {
        width: 1px;
        background-color: $c-line-gray;
    }

    &__column-contents {
        width: 304px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__input-wrapper {
        margin-top: 16px;
    }
}
