.app-text-area {
    &__wrapper {
        display: flex;
        flex-direction: row;
        border: 1px solid $c-line-gray;
        border-radius: 6px;
    }

    &__input {
        height: 100px;
        display: flex;
        flex: 1;
        resize: none;
        border: none;
        border-radius: 6px;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: $text-size-s;
        color: $c-dark-gray;
        font-weight: $text-weight-normal;
        font-family: "Roboto", sans-serif;

        &::placeholder {
            color: $c-lightest-gray;
            font-weight: $text-weight-light;
        }

        &:focus {
            outline: none;
        }
    }

    &__error {
        border: 1px solid $c-red;
    }
    &__focused {
        border: 1px solid $c-blue;
    }

    &__label {
        display: flex;
        pointer-events: none;
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;
        color: $c-lightest-gray;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
    }

    // hide scrollbar
    ::-webkit-scrollbar {
        width: 0px;
    }

    &__disabled {
        background-color: $c-gray;
        border: 1px solid $c-gray;
    }
}
