.app-dropdown {
    .css-qc6sy-singleValue {
        width: 160px !important;
        font-weight: $text-weight-normal !important;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
    }

    &__title {
        position: absolute;
        pointer-events: none;
        right: 40px;
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;
        color: $c-lightest-gray;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__container {
        color: $c-dark-gray;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        width: 100%;
    }

    &__control {
        border: 1px solid $c-line-gray !important;
        &--is-focused {
            box-shadow: none !important;
            border-color: $c-blue !important;
            .css-qc6sy-singleValue {
                width: 100% !important;
            }
        }
        &:hover {
            cursor: text !important;
        }
    }

    &__border-error {
        .app-dropdown__control {
            border: 1px solid $c-red !important;
        }
    }

    &__value-container {
        padding: 2px 16px !important;
    }

    &__input {
        color: $c-dark-gray !important;
    }

    // placeholder
    .css-14el2xx-placeholder {
        color: $c-lightest-gray !important;
        font-weight: $text-weight-light !important;
    }

    .css-1insrsq-control {
        background-color: $c-gray !important;
        border: 1px solid $c-gray !important;
    }

    &__single-value--is-disabled {
        color: $c-dark-gray !important;
    }

    &__menu {
        margin: 0px !important;
        border-radius: 6px !important;
        z-index: 999 !important;
        &-list {
            margin: 0px !important;
            padding: 0px !important;
        }
    }

    &__option {
        margin: 0px !important;
        padding: 8px 16px !important;
        &--is-selected {
            background-color: $c-lightest-blue !important;
            color: $c-dark-gray !important;
        }
        &--is-focused {
            background-color: $c-lightest-blue !important;
            color: $c-dark-gray !important;
        }
    }

    &__indicator-separator {
        width: 0px !important;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        margin-top: 4px;
    }

    &__clear-indicator {
        display: none !important;
    }
}
