.app-checkbox {
    &__label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        z-index: 99;

        /* On mouse-over, add a grey background color */
        &:hover .app-checkbox__input ~ .app-checbox__checkmark {
            background-color: #ccc;
        }

        /* On mouse-over, add a grey background color */
        &:hover .app-checkbox__input ~ .app-checbox__select-all {
            background-color: #ccc;
        }
    }

    /* Create a custom checkbox */
    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: inherit;
        border: 1px solid $c-lightest-gray;
        border-radius: 3px;

        &--error {
            border: 1px solid $c-red;
        }

        &--small {
            transform: scale(0.8);
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5.5px;
            top: 2.5px;
            width: 5px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &__select-all {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: inherit;
        border: 1px solid $c-lightest-gray;
        border-radius: 3px;

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;

            left: 3px;
            top: 7px;
            width: 10px;
            height: 2px;
            background-color: $c-white;
        }
    }

    /* Hide the browser's default checkbox */
    &__input {
        position: absolute;
        opacity: 1;
        cursor: pointer;
        height: 0 !important;
        width: 0 !important;
        top: 0px;
        left: 0px;

        /* When the checkbox is checked, add a blue background */
        &:checked ~ .app-checkbox__checkmark {
            background-color: $c-blue;
            border: 1px solid $c-blue;
        }

        /* Show the checkmark when checked */
        &:checked ~ .app-checkbox__checkmark:after {
            display: block;
        }

        /* When the checkbox is checked, add a blue background */
        &:checked ~ .app-checkbox__select-all {
            background-color: $c-blue;
            border: 1px solid $c-blue;
        }

        /* Show the checkmark when checked */
        &:checked ~ .app-checkbox__select-all:after {
            display: block;
        }
    }
}
