.page-create-coaching-session {

    width: 624px;
    display: flex;
    flex-direction: column;

    &__column-modal-left {
        width: 50%;
        float: left;
        padding-right: 8px;
        .column-modal-left {
            &__checkbox-message {
                color: $c-red;
                position: absolute;
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }

            &__checkbox-gap {
                height: 33px;
            }

            &__checkbox-wrapper {
                display: flex;
                flex-direction: row;
                position: relative;
                height: 20px;
                margin-bottom: 24px;
            }

            &__checkbox-label {
                color: $c-dark-gray;
                font-weight: $text-weight-normal;
                font-size: $text-size-s;
                position: absolute;
                top: -1px;
                left: 25px;
            }

            &__input-wrapper {
                margin-bottom: 16px;
            }
        }
    }

    &__column-modal-right {
        width: 50%;
        float: right;
        padding-left: 8px;
        .column-modal-right {
            &__cancel-wrapper {
                margin-top: 8px;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 22px;
                margin-right: 16px;
            }

            &__cancel {
                color: $c-red;
                font-size: $text-size-s;
                font-weight: $text-weight-normal;
            }

            &__input-wrapper {
                margin-bottom: 16px;
            }

            &__input-date-wrapper {
                margin-top: 76px;
                margin-bottom: 16px;
            }

            &__time-wrapper {
                margin-bottom: 16px;
            }
        }
    }

    &__column-modal-bottom {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        justify-content: flex-end;

        .column-modal-bottom {
            &__modal-input-button-row {
                display: flex;
                flex-direction: row;
            }

            &__modal-input-button {
                height: 43px;
                width: 144px;
                margin-left: 16px;
            }
        }
    }
}
