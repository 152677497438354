.app-input-display {
    &__container {
        border-radius: 6px;
        height: 38px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        color: $c-dark-gray;
        font-size: $text-size-s;
    }
    &--gray {
        background-color: $c-gray;
    }
    &--blue {
        background-color: $c-lightest-blue;
    }
    &__benefit {
        font-weight: $text-weight-normal;
    }
    &__type {
        font-weight: $text-weight-bold;
    }
}
