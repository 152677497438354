.page-reset-password {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__contents {
        min-width: 304px;
    }

    &__logo {
        height: 40px;
        width: 90px;
        margin-bottom: 24px;
    }

    &__title {
        font-size: $text-size-xxxl;
        font-weight: $text-weight-normal;
        margin-bottom: 8px;
    }

    &__subtitle {
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
        color: $c-lightest-gray;
        margin-bottom: 24px;
    }

    &__input {
        margin-bottom: 24px;
    }

    &__button-wrapper {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }

    &__button {
        width: 48%;
        height: 43px;
    }
}
