.app-tab-link {
    &__wrapper {
        display: flex;
        flex-direction: row;
    }

    &__item {
        height: 35px;
        width: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: inherit;
        margin-right: 16px;
        color: $c-lightest-gray;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        &:hover {
            cursor: pointer;
        }
    }

    &__active-tab {
        background-color: $c-amber;
        color: $c-dark-gray;
    }

    &--border {
        margin-top: -1px;
        border-bottom: 1px solid $c-line-gray;
    }
}
