.app-dropdown-mini {
    &__dropdown {
        .btn {
            &:active {
                box-shadow: none !important;
            }
        }

        &-toggle {
            background-color: inherit !important;
            color: $c-dark-gray !important;
            border: none !important;
            padding: 0px 24px !important;
            margin: 0px !important;

            border: none !important;
            outline: none !important;
            text-decoration: none !important;

            &:active {
                border: none !important;
                outline: none !important;
                text-decoration: none !important;
            }
        }

        &-menu {
            padding: 0px !important;
            margin: 10px 0px 0px 40px !important;
            border: none !important;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            font-size: $text-size-s !important;
            min-width: 135px !important;

            &-positioning {
                top: -10px !important;
                left: -20px !important;
            }

            border: none !important;
            outline: none !important;
            text-decoration: none !important;

            &:active {
                border: none !important;
                outline: none !important;
                text-decoration: none !important;
            }
        }

        &-item {
            height: 32px;
            padding: 0px 8px !important;
            &:hover {
                background-color: $c-lightest-blue !important;
            }
            &:active {
                color: $c-dark-gray !important;
            }
        }

        &:active {
            border: none !important;
            outline: none !important;
            text-decoration: none !important;
        }
    }
}
