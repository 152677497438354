.page-employee-confirmation {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__button-row {
        display: flex;
        flex-direction: row;
    }

    &__button-wrapper {
        height: 38px;
        width: 200px;
        margin-left: 8px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    &__button-icon {
        width: 16px;
        &--calendar-enable {
            filter: invert(31%) sepia(98%) saturate(1195%) hue-rotate(193deg) brightness(90%) contrast(94%);
        }
        &--letter-enable {
            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(86deg) brightness(116%) contrast(101%);
        }
    }


    &__modal-attachment {
        width: 80vh;
        height: 95vh;
    }

    .react-table {
        &__header-checkbox-wrapper {
            margin-bottom: 30px;
            margin-left: 25px;
        }

        &__row-checkbox-wrapper {
            margin-top: -22px;
            margin-left: 26px;
            position: relative;
        }

        &__row-checkbox-disable {
            background-color: $c-gray;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 14px;
            border-radius: 3px;
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }

            &--cursor-default {
                &:hover {
                    cursor: default;
                }
            }
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__dropdown {
            position: absolute;
            margin-left: 45px;
            margin-top: 5px;
        }

        &__attachment {
            margin-left: 18px;
            &-icon {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__status {
            &-header {
                &:hover {
                    cursor: pointer;
                    .react-table__icon-filter {
                        display: inline-block;
                    }
                }
            }

            &-data {
                border-radius: 999px;
                height: 28px;
                width: 97px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--green {
                background-color: $c-lightest-green;
            }

            &--blue {
                background-color: $c-lightest-blue;
            }
        }

        &__icon-filter {
            margin-left: 12px;
            display: none;
            &--active {
                filter: brightness(0.5);
                display: inline-block;
            }
        }

    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
