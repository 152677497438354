.app-time-picker {
    &__input-wrapper {
        width: 100%;
        height: 38px;
        border: 1px solid $c-line-gray;
        border-radius: 6px;
        display: flex;
        align-items: center;

        &--focused {
            border: 1px solid $c-blue;
        }

        &--error {
            border: 1px solid $c-red;
        }

        &--disabled {
            border: 1px solid $c-gray;
            background-color: $c-gray;
        }
    }

    &__input {
        font-weight: $text-weight-normal;
        font-size: $text-size-s;
        color: $c-dark-gray;
        padding-left: 16px;
        flex: 1;
        border: none;
        outline: none;

        &::placeholder {
            color: $c-lightest-gray;
            font-weight: $text-weight-light;
            font-size: $text-size-s;
        }

        &--disabled {
            background-color: $c-gray;
        }
    }

    &__placeholder-label {
        font-weight: $text-weight-medium;
        color: $c-lightest-gray;
        font-size: $text-size-xxs;
        margin-right: 16px;
    }

    &__wrapper {
        display: flex;
        position: absolute;
        flex-direction: row;
        width: 144px;
        padding: 8px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        background-color: $c-white;
        z-index: 9;
    }

    &__wrapper-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 20px;
        justify-content: center;
    }

    &__arrow-button {
        border: none;
        background-color: inherit;
        &:active {
            filter: brightness(1.25);
        }
    }

    &__semicolon {
        color: $c-dark-gray;
    }

    &__value-input {
        width: 32px;
        height: 24px;
        border: 0.5px solid $c-line-gray;
        border-radius: 2px;
        background-color: $c-blue-gray-bright;
        outline: none;
        text-align: center;
    }

    &__ampm {
        height: 16px;
        width: 32px;
        font-weight: $text-weight-normal;
        font-size: $text-size-xs;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;

        &--active {
            color: $c-white;
            background-color: $c-blue;
        }
    }

    &__ampm-divider {
        height: 4px;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
    }
}
