.page-leave-directory {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }
    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    .react-table {
        &__employee-id {
            &-header {
                margin-left: 24px;
            }

            &-data {
                margin-left: 24px;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__status {
            &-header {
                &:hover {
                    cursor: pointer;
                    .react-table__icon-filter {
                        display: inline-block;
                    }
                }
            }

            &-data {
                border-radius: 999px;
                height: 28px;
                width: 97px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-working {
                background-color: $c-lightest-green;
            }

            &-on-leave {
                background-color: $c-lightest-red;
            }
        }

        &__icon {
            &-arrow {
                margin-left: 12px;
                height: 8px;
            }
            &-filter {
                margin-left: 12px;
                display: none;
                &--active {
                    filter: brightness(0.5);
                    display: inline-block;
                }
            }
        }

        &__dropdown {
            position: absolute;
            margin-left: 45px;
            margin-top: 5px;
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
