.app-input-with-label {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 38px;
        border: 1px solid $c-line-gray;
        border-radius: 6px;
    }

    &__input-error {
        border: 1px solid $c-red;
    }

    &__focused {
        border: 1px solid $c-blue;
    }

    &__disabled {
        background-color: $c-gray;
        border: 1px solid $c-gray;
    }

    &__onClick {
        &:hover {
            cursor: pointer;
        }
    }

    &__input {
        outline: none;
        border: none;
        display: flex;
        flex: 1;
        border-radius: 6px;
        text-overflow: ellipsis;
        padding-left: 16px;
        font-size: $text-size-s;
        color: $c-dark-gray;
        font-weight: $text-weight-normal;

        &::placeholder {
            color: $c-lightest-gray;
            font-weight: $text-weight-light;
            font-size: $text-size-s;
        }

        &:disabled {
            background-color: $c-gray;
            border: 1px solid $c-gray;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    &__label {
        pointer-events: none;
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;
        color: $c-lightest-gray;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 15px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        white-space: nowrap;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        margin-top: 4px;
    }
}
