.page-leave-calendar {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__button-row {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    &__new-button {
        width: 125px;
        height: 40px;
    }

    &__calendar-icon {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%);
        height: 16px;
        width: 16px;
    }

    &__body {
        position: relative;
        display: flex;
        flex-direction: row;
    }

    &__calendar-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__color-icon {
        height: 12px;
        width: 12px;
    }

    &__legend {
        &-contents {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
        &-button {
            position: absolute;
            right: 0px;
            height: 100%;
            color: $c-dark-blue;
            &:hover {
                cursor: pointer;
            }
        }

        &-icon-wrapper {
            width: 20px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-right: 5px;
        }

        &-icon {
            height: 20px;
            width: 20px;
        }

        &-close {
            position: absolute;
            right: 10px;
            top: 8px;

            &-icon {
                height: 10px;
                width: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &-wrapper {
            position: absolute;
            right: -150px;
            top: 30px;
            background-color: $c-white;
            z-index: 9;
            border-radius: 6px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            padding: 18px;
            font-size: $text-size-xxs;
            color: $c-darker-gray;
        }

        &-label {
            display: flex;
            align-items: flex-end;
        }
    }
}

.fc {
    &-prev-button {
        background-color: $c-gray !important;
        color: $c-dark-gray !important;
        border: 0px !important;
        border-right: 2px solid $c-line-gray !important;
        height: 32px !important;
        width: 32px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        &:focus {
            box-shadow: none !important;
        }

        .fc-icon {
            font-size: 20px !important;
            color: $c-dark-gray !important;
        }
    }

    &-next-button {
        background-color: $c-gray !important;
        color: $c-dark-gray !important;
        border: 0px !important;
        border-left: 2px solid $c-line-gray !important;
        height: 32px !important;
        width: 32px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        &:focus {
            box-shadow: none !important;
        }

        .fc-icon {
            font-size: 20px !important;
            color: $c-dark-gray !important;
        }
    }

    &-button-primary {
        &:active {
            outline: none !important;
            border: none !important;
        }
    }

    &-today-button {
        background-color: inherit !important;
        color: $c-blue !important;
        border: 1px solid $c-blue !important;
        font-size: $text-size-s !important;
        font-weight: $text-weight-normal !important;
        height: 32px !important;
    }

    &-toolbar-title {
        color: $c-blue !important;
        font-size: $-text-size-ml !important;
        font-weight: $text-weight-semi-bold !important;
        margin-left: -100px !important;
    }

    &-col-header-cell-cushion {
        color: $c-dark-gray !important;
        font-size: $text-size-body !important;
        font-weight: $text-weight-normal !important;
    }

    &-daygrid-day-number {
        color: $c-dark-gray !important;
    }

    &-daygrid-body {
        z-index: 0 !important;
    }

    &-day-today {
        background-color: inherit !important;
        // color: $c-amber !important;
        .fc-daygrid-day-number {
            color: $c-amber !important;
        }
    }

    &-day-sat {
        background-color: $c-gray;
    }

    &-day-sun {
        background-color: $c-gray;
    }

    &__event {
        background-color: $c-white;
        white-space: pre-wrap;
        padding: 2px 0 2px 0;
        margin-bottom: 1px;
    }

    &__event--red {
        background-color: $c-lightest-red;
    }

    &__event--green {
        background-color: $c-lightest-green;
    }

    &__event--blue {
        background-color: $c-lightest-blue;
    }

    &__view {
        background-color: $c-white;
    }

    &__day-header {
        background-color: $c-white !important;
        margin-bottom: 10px;
    }

    &__event {
        &-wrapper {
            width: 100%;
            display: flex;
            position: relative;
            margin-bottom: -8px;
        }

        &-title {
            color: $c-dark-gray;
            font-size: $text-size-xxs;
            font-weight: $text-weight-normal;
            margin-left: 5px;
            margin-top: 1px;
            border: none;
            padding: 0px;
            margin-bottom: 0px;
            height: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &-event-main {
        display: flex;
        flex-direction: row;
    }

    &-event-img {
        display: flex;
        width: 15px;
        justify-content: center;
        margin-left: 5px;
        img {
            height: 10px;
        }
    }

    &-daygrid-more-link {
        color: $c-dark-gray;
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;
    }

    &__icon-wrapper {
        height: 100%;
        margin-top: -4px;
        padding-left: 3px;
    }

    &__event-icon {
        height: 20px;
        width: 20px;
    }

    &__others-icon {
        margin-top: 1px;
        height: 13px;
        width: 13px;
    }

    &__tooltip {
        background-color: $c-white !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 6px !important;
        padding: 8px !important;
        opacity: 1 !important;
        width: 144px !important;
        position: relative;
    }

    &__tooltip-wrapper {
        display: flex;
        flex-direction: row;
    }

    &__tooltip-icon {
        margin-right: 6.5px;
        margin-top: -3px;
    }

    &__tooltip-title {
        font-size: $text-size-xxs;
        color: $c-dark-gray;
    }

    &__tooltip-close-icon {
        height: 9px;
        width: 9px;
        position: absolute;
        right: 8px;
        top: 10px;
        cursor: pointer;
    }

    &-popover {
        border-radius: 6px;
        background-color: $c-white !important;
    }

    &-popover-header {
        position: absolute;
        right: 2px;
        top: 3px;
        background-color: inherit !important;
    }

    &-popover-body {
        max-width: 144px !important;
        min-width: 144px !important;
        padding: 0px 8px !important;

        .fc-daygrid-event-harness {
            border-bottom: 1px solid $c-line-gray;
            padding-top: 4px;
            padding-bottom: 4px;
            &:last-child {
                border-bottom: none;
            }
            .fc__event--red {
                background-color: inherit !important;
            }
            .fc__event--green {
                background-color: inherit !important;
            }
        }
    }

    &-popover {
        width: 144px;
    }

    &-more-popover {
        display: flex !important;
        flex-direction: column !important;
    }

    &-more-wrapper {
        display: flex;
        flex-direction: row;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $c-line-gray;
        &:last-child {
            border: none;
        }
    }

    &-more-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        margin-top: -4px;
    }

    &-more-others-icon {
        width: 13px;
        height: 13px;
        margin-right: 6px;
        margin-left: 2px;
        
    }

    &-more-title {
        font-size: $text-size-xxs;
        color: $c-dark-gray;
        font-weight: $text-weight-normal;
        width: 70%;
        word-break: break-word;
    }
}
