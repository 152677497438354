.app-input-number {
    display: flex;
    flex-direction: row;
    height: 38px;
    border: 1px solid $c-line-gray;
    border-radius: 6px;

    &--focus {
        border: 1px solid $c-blue !important;
    }
    &--unfocus {
        border: 1px solid $c-line-gray;
    }

    &__input {
        border: none;
        flex: 1;
        border-radius: 6px;
        outline: none;
        color: $c-dark-gray;
        padding-left: 15px;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;

        &::placeholder {
            color: $c-lightest-gray;
            font-weight: $text-weight-light;
        }
    }

    &__button-wrapper {
        margin-right: 17px;
        margin-left: 17px;
        display: flex;
        flex-direction: column;
        margin-top: -1px;
    }

    &__arrow {
        &-up {
            height: 50%;
            border: none;
            background-color: inherit;
            &:hover {
                cursor: pointer;
                filter: brightness(0.6);
            }
        }

        &-down {
            border: none;
            background-color: inherit;
            transform: rotate(180deg);
            height: 50%;
            margin-top: 2px;
            // margin-top: 2px;
            &:hover {
                cursor: pointer;
                filter: brightness(0.6);
            }
        }
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
    }

    &--error {
        border: 1px solid $c-red;
    }
}
