.page-employee-dashboard {
    &__header {
        margin-top: 24px;
        margin-bottom: 30px;
    }

    &__header-welcome {
        color: $c-blue;
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
    }

    &__header-datetime {
        color: $c-lightest-gray;
        font-weight: $text-weight-medium;
        font-size: $text-size-s;
    }

    &__contents {
        display: flex;
        flex-direction: row;
        margin: -8px;
    }

    &__column-1 {
        flex: 7;
    }

    &__column-2 {
        flex: 3;
        display: flex;
        flex-direction: column;
    }

    &__info-row {
        display: flex;
        flex-direction: row;
    }

    &__card-styling {
        position: relative;
        margin: 8px;
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        padding: 16px;
        
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #617082;
            border-radius: 999px;
        }
    }

    &__card-contents-title {
        color: $c-dark-gray;
        font-size: $text-size-body;
        font-weight: $text-weight-normal;
    }

    &__arrow-right {
        height: 9.5px;
        margin-left: 7px;
        filter: invert(35%) sepia(87%) saturate(1001%) hue-rotate(187deg) brightness(88%) contrast(102%);
    }

    .medium-card {
        height: 100%;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__view-all-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $c-blue;
            font-size: $text-size-xxs;
            font-weight: $text-weight-normal;
            background-color: inherit;
            border: none;
        }

        &__view-all {
            padding-top: 1px;
        }

        &__body {
            margin-top: 14px;
        }

        &__empty-card {
            height: 80px;
        }
    }

    .announcement {
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__contents {
            height: 300px;
            overflow-y: scroll;

            &::-webkit-scrollbar-track {
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar {
                width: 4px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background: #617082;
                border-radius: 999px;
            }
        }

        &__contents-row-wrapper {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $c-blue-gray;

            &:last-child {
                border-bottom: none;
            }
        }

        &__contents-text-column {
            flex: 4;
        }

        &__contents-title {
            color: $c-blue;
            font-weight: $text-weight-medium;
            font-size: $text-size-xs;
            padding-top: 16px;
        }

        &__contents-details {
            color: $c-dark-gray;
            font-weight: $text-weight-normal;
            font-size: $text-size-xs;
            padding-top: 8px;
            padding-bottom: 16px;
        }

        &__contents-button-column {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-right: 16px;
        }

        &__pdf-file {
            height: 24px;
            width: 18px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .celebration-corner {
        display: flex;
        flex-direction: column;

        &__contents {
            margin-top: 12px;
            overflow-y: scroll;
            position: relative;

            &::-webkit-scrollbar-track {
                margin-bottom: 4px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar {
                width: 4px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background: #617082;
                border-radius: 999px;
                position: absolute;
                right: 0px;
            }
        }

        &__empty-card {
            height: 50px;
        }
    }

    .holiday-this-month {
        &__contents {
            margin-top: 14px;
        }

        &__empty-card {
            height: 50px;
        }
    }

    .pending {
        &__pending-button {
            background-color: inherit;
            border: none;
            font-weight: $text-weight-normal;
            color: $c-blue;
            font-size: $text-size-xxs;
            margin: 8px 0px;
        }

        &__pending-value {
            font-size: $text-size-xxl;
            font-weight: $text-weight-medium;
        }

        &__contents {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 8px;
            border-bottom: 1px solid $c-blue-gray;
            &:first-child {
                padding-top: 0px;
                margin-bottom: 8px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__empty-card {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        color: $c-lightest-gray;
    }
}

.employee-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;

    &__details-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
    }

    &__details {
        color: $c-dark-gray;
        font-weight: $text-weight-normal;
        font-size: $text-size-xs;
    }

    &__details-img {
        height: 24px;
        width: 24px;
        border-radius: 999px;
        margin-right: 16px;
    }

    &__info {
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;
        white-space: nowrap;

        &--red {
            color: $c-red;
        }

        &--blue {
            color: $c-blue;
        }
    }
}
