.app-new-claim {
    &__input-wrapper {
        width: 304px;
        margin-bottom: 16px;
    }

    &__button-row {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__button-wrapper {
        height: 43px;
        width: 144px;
    }
}