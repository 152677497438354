.app-dual-columns-modal {
    &__container {
        display: flex;
        flex-direction: row;
    }

    &__left {
        width: 304px;
        margin-right: 8px;
    }

    &__right {
        width: 304px;
        margin-left: 8px;
    }

    &__bottom {
        display: flex;
        justify-content: flex-end;
    }
}
