.app-modal-confirmation {
    &__body {
        width: 272px;
        &-content {
            color: $c-dark-gray;
            font-size: $text-size-body;
        }
    }

    &__button-row {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__button-wrapper {
        height: 32px;
        width: 73px;
        margin-left: 8px;
    }
}
