.page-pending-claim {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__button-row {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    &__new-button {
        width: 125px;
        height: 40px;
    }

    &__calendar-icon {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%);
        height: 16px;
        width: 16px;
    }

    &__user {
        &-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $c-dark-gray;
            margin-bottom: 30px;

            &--left {
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }
        }
        &-id {
            border-left: 1px solid $c-line-gray;
            padding-left: 8px;
            margin-left: 8px;
        }
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__table-wrapper {
        margin-top: 24px;

        .react-table {
            &__claim-type {
                &-header {
                    margin-left: 25px;
                }
                &-data {
                    margin-left: 25px;
                }
            }

            &__amount {
                display: flex;
                justify-content: flex-end;
                margin-right: 70px;
            }

            &__attachment {
                width: 20px;
                display: flex;
                justify-content: center;
                margin-left: 25px;

            }
        }
    }

    &__table-header {
        &-row {
            display: flex;
            flex-direction: row;
        }

        &-button-wrapper {
            height: 32px;
            width: 122px;
            margin-right: 8px;
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__modal {
        width: 304px;

        &-subtitle {
            color: $c-lightest-gray;
            font-weight: $text-weight-medium;
            font-size: $text-size-xs;
        }

        &-input {
            margin-top: 16px;
        }

        &-button-row {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
        }

        &-button {
            height: 43px;
            width: 144px;
        }

        &-attachment {
            width: 80vh;
            height: 95vh;
        }

        &-image {
            object-fit: cover;
            max-height: 90vh;
            max-width: 90vw;
        }
    }

    &__modal {
        &-attachment {
            width: 80vh;
            height: 95vh;
        }

        &-input-wrapper {
            margin-bottom: 16px;
            position: relative;

            &--hover {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &-icon-wrapper {
            position: absolute;
            left: 15px;
            top: 8px;
        }
    }

    &__button {
        &-row {
            display: flex;
            flex-direction: row;
        }
        &-wrapper {
            width: 144px;
            height: 42px;
            margin-left: 16px;
        }
    }
}
