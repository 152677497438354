.app-dropdown-multi-select {
    &__container {
        color: $c-dark-gray;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        width: 100%;
    }

    &__placeholder {
        color: $c-lightest-gray !important;
    }

    .css-1okebmr-indicatorSeparator {
        background-color: inherit;
    }

    &__control {
        border: 1px solid $c-line-gray !important;
        &--is-focused {
            box-shadow: none !important;
            border-color: $c-blue !important;
        }
    }

    &__option {
        &-label {
            padding-left: 34px;
            padding-bottom: 0px;
            padding-top: 0px;
            margin: 0px;
            line-height: 1;
        }

        &-checkbox {
            // background-color: red;
        }

        &--is-selected {
            background-color: $c-lightest-blue !important;
            color: $c-dark-gray !important;
        }
        &--is-focused {
            background-color: $c-lightest-blue !important;
            color: $c-dark-gray !important;
        }
    }

    &__menu-list {
        padding: 0px !important;
        border-radius: 6px;
    }

    &__option {
        padding: 1px 0px 10px 8px !important;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
    }

    &__error-border {
        border: 1px solid $c-red !important;
        border-radius: 6px;
    }
}
