.app-dropdown-checkbox {
    background-color: $c-white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 100px;

    &__label {
        position: absolute;
        padding-left: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    &__input {
        margin-top: 3px;
        margin-left: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    &__item {
        padding-top: 6px;
        &:last-child {
            padding-bottom: 6px;
        }
    }
}
