.app-image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__input {
        width: 0px;
        height: 0px;
        position: absolute;
        top: -100px;
        left: -100px;
    }

    &__placeholder-image {
        height: 180px;
        width: 180px;
        border-radius: 999px;
        object-fit: cover;
        background-color: $c-blue-gray-bright;
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
            background-color: rgba(68, 68, 68, 0.5);
        }
    }

    &__image-wrapper {
        position: relative;
    }

    &__image {
        height: 180px;
        width: 180px;
        border-radius: 999px;
        object-fit: cover;
        margin: 0px;
        padding: 0px;

        &-camera-wrapper {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 180px;
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 999px;

            &:hover {
                background: rgba(68, 68, 68, 0.5);
                cursor: pointer;
            }

            .app-image-upload__camera-icon {
                visibility: hidden;
            }

            &:hover .app-image-upload__camera-icon {
                visibility: visible;
                filter: invert(89%) sepia(100%) saturate(0%) hue-rotate(211deg) brightness(108%) contrast(108%);
            }
        }
    }

    &__camera-icon {
        height: 32px;
        width: 32px;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        margin-top: 10px;
    }
}
