.page-login {
    display: flex;
    flex-direction: column;
    max-width: 325px;

    &__logo {
        height: 40px;
        width: 90px;
        margin-bottom: 24px;
    }

    &__title {
        font-weight: $text-weight-bold;
        font-size: $text-size-xxxl;
        color: $c-blue;
        margin-bottom: 8px;
    }

    &__subtitle {
        font-size: $text-size-m;
        color: $c-lightest-gray;
        font-weight: $text-weight-medium;
        margin-bottom: 24px;
    }

    &__input-wrapper {
        margin-bottom: 16px;
        @include max-width($scr-xlg) {
            width: 304px;
        }
        @include min-width($scr-xlg) {
            width: 325px;
        }
    }

    &__forgot-password-wrapper {
        justify-content: flex-end;
        display: flex;
        margin-top: -8px;
    }

    &__forgot-password {
        color: $c-blue;
        font-size: $text-size-s;
        font-weight: $text-weight-medium;
    }

    &__buttons-wrapper {
        height: 43px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 24px;
    }

    &__button {
        width: 48%;
    }
}
