.page-leave-summary {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__button-row {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    &__new-button {
        width: 125px;
        height: 40px;
    }

    &__calendar-icon {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(23deg) brightness(118%) contrast(118%);
        height: 16px;
        width: 16px;
    }

    &__user {
        &-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $c-dark-gray;
            margin-bottom: 30px;

            &--left {
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }
        }
        &-id {
            border-left: 1px solid $c-line-gray;
            padding-left: 8px;
            margin-left: 8px;
        }
        &-status {
            width: 97px;
            height: 28px;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;
            &--green {
                background-color: $c-lightest-green;
            }
            &--red {
                background-color: $c-lightest-red;
            }
        }
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    .react-table {
        &__leave-type {
            &-header {
                padding-left: 24px;
            }
            &-row {
                padding-left: 24px;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__header-checkbox-wrapper {
            margin-bottom: 30px;
            margin-left: 25px;
        }

        &__row-checkbox-wrapper {
            margin-top: -22px;
            margin-left: 26px;
        }

        &__table-data {
            margin-left: 30px;
        }

        &__days-row {
            margin-left: 10px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__cross-img {
            height: 12px;
            width: 12px;
            
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__table-title {
        margin-top: 32px;
        margin-bottom: 16px;
        color: $c-dark-gray;
        font-weight: $text-weight-medium;
        font-size: $text-size-body;
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    
    &__table-header-button-wrapper {
        width: 113px;
        height: 32px;
    }
}
