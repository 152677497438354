$buttonHoverShadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
$buttonActiveBright: brightness(110%);

.app-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    font-weight: $text-weight-normal;

    &:hover {
        cursor: pointer;
    }

    &__solid-disabled {
        background-color: $c-line-gray;
        color: $c-lightest-gray;
        border: none;

        &:hover {
            cursor: default;
        }
    }

    &__solid-enable {
        background-color: $c-blue;
        border: none;
        color: $c-white;

        &:hover {
            background-color: $c-light-blue;
            box-shadow: $buttonHoverShadow;
        }
        &:active {
            filter: $buttonActiveBright;
        }
    }

    &__outline-disabled {
        background-color: inherit;
        color: $c-lightest-gray;
        border: 1px solid $c-lightest-gray;

        &:hover {
            cursor: default;
        }
    }

    &__outline-enable {
        background-color: inherit;
        border: 1px solid $c-blue;
        color: $c-blue;

        &:hover {
            color: $c-white;
            background-color: $c-light-blue;
            border: 1px solid $c-light-blue;
            box-shadow: $buttonHoverShadow;
            img {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(60deg) brightness(105%) contrast(102%) !important;
            }
        }
        &:active {
            filter: $buttonActiveBright;
        }
    }

    &__text-enable {
        background-color: inherit;
        border: none;
        color: $c-blue;

        &:hover {
            background-color: $c-lightest-blue;
            box-shadow: $buttonHoverShadow;
        }
    }

    &__text-disabled {
        background-color: inherit;
        color: $c-lightest-gray;
        border: none;

        &:hover {
            cursor: default;
        }
    }

    &__small {
        font-size: $text-size-s;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100%;

        .app-button__icon {
            // margin-bottom: -1px;
            margin-bottom: 2px;
            margin-right: 9px;

            &--disabled {
                filter: invert(68%) sepia(0%) saturate(149%) hue-rotate(162deg) brightness(89%) contrast(94%);
            }
        }
    }

    &__medium {
        font-size: $text-size-body;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;

        .app-button__icon {
            margin-bottom: -2px;
            margin-right: 12.25px;
        }
    }

    &__large {
        font-size: $text-size-m;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        .app-button__icon {
            margin-bottom: -4px;
            margin-right: 15px;
        }
    }
}
