.app-auth-layout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $c-blue-white;
    padding-left: 3%;
    padding-right: 3%;

    &__image {
        background-image: url("../../assets/images/pages/authentication/auth-image.svg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
        margin-bottom: 5%;
    }

    &__content {
        width: 50%;
        margin-left: auto;
        margin-top: 2.5%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include max-width($scr-md) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__item {
        background-color: white;
        min-height: 600px;
        min-width: 464px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0px 0px 32px rgba(95, 135, 187, 0.1);

        @include min-width($scr-xlg) {
            height: 70%;
            width: 60%;
        }
    }
}
