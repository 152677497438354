.app-react-table {
    &__table-wrapper {
        width: 100%;
        border-spacing: 0;
        border-radius: 6px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }

    .header {
        &__row {
            background-color: $c-table-header;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            color: $c-lightest-gray;
            font-size: $text-size-xs;
            height: 50px;
            display: flex;
            align-items: center;
            border: 1px solid $c-line-gray;

            &-header {
                display: flex;
                justify-content: flex-start;
                font-weight: $text-weight-medium !important;
                margin-right: 10px;

                &-buttons {
                    display: flex;
                    position: absolute;
                    margin-left: 220px;
                    height: 50px;
                    align-items: center;
                }

                &-divider {
                    position: absolute;
                    left: -16px;
                    background-color: $c-line-gray;
                    width: 1px;
                    height: 16px;
                }
            }
        }

        &__selected-all {
            background-color: $c-white !important;
            color: $c-dark-gray !important;
        }
    }

    .body {

        .body-scrollbar {
            &::-webkit-scrollbar-track {
                margin-bottom: 4px;
            }

            &::-webkit-scrollbar {
                width: 4px;
            }
            
            &::-webkit-scrollbar-thumb {
                background: #617082;
                border-radius: 999px;
            }
        }
        &__row {
            color: $c-dark-gray;
            font-size: $text-size-s;
            font-weight: $text-weight-normal;
            border-bottom: 1px solid $c-line-gray;
            border-left: 1px solid $c-line-gray;
            border-right: 1px solid $c-line-gray;
            min-height: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;

            &-data {
                margin-right: 10px;
            }

            &-hover {
                &:hover {
                    cursor: pointer;
                }
            }

            &-selected {
                background: linear-gradient(to right, $c-blue 0.7%, $c-lightest-blue 0.7%, $c-lightest-blue 100%);
            }

            &:last-child {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            &__row-data {
                display: flex;
                align-items: center;
                color: $c-dark-gray;
                font-size: $text-size-s;
                font-weight: $text-weight-normal;
            }
        }
    }

    &__empty-body {
        height: 237px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $c-line-gray;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        font-size: $text-size-body;
        color: $c-dark-gray;
        margin: 0px;

        &-row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-detail-1 {
            font-weight: $text-weight-medium;
            margin-bottom: 4px;
        }

        &-detail-2 {
            margin-top: 4px;
        }
    }

    &__input {
        width: 50px;
        height: 18px;
        text-align: center;
        border: 1px solid $c-line-gray;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
}
