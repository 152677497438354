.app-input-upload-attachment {
    &__label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        height: 38px;
        border: 1px solid $c-line-gray;
        padding: 0 16px;
        margin: 0px;
        &:hover {
            cursor: pointer;
        }
    }

    &__label--error {
        border: 1px solid red !important;
    }

    &__value {
        width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $c-dark-gray;
        font-weight: $text-weight-normal;
        font-size: $text-size-s;
        height: 20px;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0px;
        height: 0px;
        opacity: 0;
    }

    &__placeholder {
        color: $c-lightest-gray;
        font-weight: $text-weight-light;
        font-size: $text-size-s;
    }

    &__upload-icon {
        color: $c-lightest-gray;
    }

    &--error {
        color: $c-red;
        font-size: $text-size-xs;
        margin-top: 4px;
    }
}
