.page-timesheet-management {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__button-wrapper {
        height: 40px;
        width: 97px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    .react-table {
        
        &__header-checkbox-wrapper {
            margin-bottom: 30px;
            margin-left: 25px;
        }
        &__row-checkbox-wrapper {
            margin-top: -22px;
            margin-left: 26px;
        }

        &__username {
            &-header {
                margin-left: 24px;
            }

            &-data {
                margin-left: 24px;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__input {
            width: 50px;
            height: 18px;
            text-align: center;
            border: 1px solid $c-line-gray;
            border-radius: 4px;

            &:focus {
                outline: none;
            }
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__search-content {
        border: 1px solid $c-blue-gray;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }

    &__input {
        width: 624px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &-wrapper {
            width: 304px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    &__advance-button {
        &-row {
            display: flex;
            flex-direction: row;
        }
        &-wrapper {
            height: 32px;
            width: 66px;
            margin-left: 8px;
        }
    }

    &__table-header {
        &-row {
            display: flex;
            flex-direction: row;
            margin-top: 24px;
            justify-content: flex-end;
        }

        &-save-button {
            height: 32px;
            width: 60px;
        }

        &-button-wrapper {
            margin-left: 16px;
        }

        &-arrow-button-left {
            border: none;
            width: 32px;
            height: 32px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: 1px solid $c-line-gray;
            &:active {
                filter: brightness(102%);
            }
        }

        &-arrow-button-right {
            border: none;
            width: 32px;
            height: 32px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: 1px solid $c-line-gray;
            &:active {
                filter: brightness(102%);
            }
        }

        &-arrow {
            color: $c-dark-gray;
            height: 13px;
        }
    }
}
