.app-table-empty {
    &__wrapper {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
    }

    &__header {
        background-color: $c-table-header;
        display: flex;
        flex-direction: row;
        height: 50px;
        align-items: center;
        color: $c-lightest-gray;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border: 1px solid $c-line-gray;
        font-size: $text-size-xs;
        font-weight: $text-weight-medium;
    }

    &__body {
        height: 237px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $c-line-gray;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        font-size: $text-size-body;
        color: $c-dark-gray;

        &-detail-1 {
            font-weight: $text-weight-medium;
            margin-bottom: 4px;
        }

        &-detail-2 {
            margin-top: 4px;
        }
    }
}
