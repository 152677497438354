.page-create-benefit {
    &__attention {
        width: 353px;
        .attention {
            &__content {
                color: $c-dark-gray;
                font-size: $text-size-body;
                font-weight: $text-weight-normal;
            }

            &__button {
                &-row {
                    margin-top: 24px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
                &-wrapper {
                    height: 32px;
                    width: 73px;
                    margin-left: 8px;
                }
            }
        }
    }

    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__name-input {
        width: 304px;
    }

    &__position-dropdown {
        width: 304px;
    }

    &__mega-dropdown-wrapper {
        margin-top: 24px;
    }

    &__contents {
        width: 100%;
    }

    &__item-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 24px;
    }

    &__item {
        margin-top: 20px;
        width: 304px;
        margin-right: 80px;
    }

    &__title {
        color: $c-dark-gray;
        font-size: $text-size-s;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__input-wrapper {
        margin-top: 13px;
    }

    &__divider {
        border-bottom: 1px solid $c-line-gray;
    }

    &__create-new-field {
        margin-top: 24px;

        .create-new-field {
            &__header {
                display: flex;
                align-items: center;
            }
            &__title {
                color: $c-dark-gray;
                font-weight: $text-weight-medium;
                font-size: $text-size-body;
                margin-right: 16px;
            }

            &__body {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                &-item {
                    margin-top: 20px;
                    margin-right: 80px;
                    width: 304px;
                }
            }

            &__toggle-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 12.5px;
            }

            &__input-wrapper {
                position: relative;
                margin-top: 8px;
            }

            &__button-wrapper {
                width: 97px;
                height: 32px;
            }

            &__delete-wrapper {
                position: absolute;
                top: 3px;
                right: -42px;
                border: none;
                background-color: inherit;
                height: 24px;
            }
        }
    }

    &__button {
        &-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 24px;
        }
        &-wrapper {
            width: 144px;
            height: 43px;
            margin-left: 16px;
        }
    }
}
