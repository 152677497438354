.page-check-email {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__icon-wrapper {
        background-color: $c-blue;
        width: 144px;
        height: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
        margin-bottom: 32px;
    }

    &__title {
        font-size: $text-size-xxxl;
        font-weight: $text-weight-normal;
        margin-bottom: 14px;
        display: flex;
        justify-content: center;
    }

    &__details {
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
        color: $c-lightest-gray;
        line-height: 21px;
        display: flex;
        justify-content: center;
        margin-bottom: 2px;
    }

    &__email {
        color: $c-light-blue;
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        margin-top: 24px;
        width: 125px;
        height: 43px;
    }
}
