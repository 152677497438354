.page-employee {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 48px;
    }

    &__image-upload-wrapper {
        justify-content: center;
        display: flex;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    &__left {
        width: 50%;
        left: auto;
        border-right: 1px solid $c-line-gray;
        display: flex;
        &-content {
            width: 304px;
        }
        @include max-width($scr-md) {
            justify-content: center;
        }
        @include min-width($scr-xlg) {
            justify-content: center;
        }
    }
    &__right {
        width: 50%;
        right: auto;
        display: flex;
        justify-content: flex-end;
        &-content {
            width: 304px;
        }
        @include max-width($scr-md) {
            justify-content: center;
        }
        @include min-width($scr-xlg) {
            justify-content: center;
        }
    }

    &__app-status-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__title {
        color: $c-dark-gray;
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
        margin-top: 24px;
    }

    &__input-wrapper {
        margin-top: 16px;
        width: 100%;
    }

    &__button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 24px;
    }

    &__button-wrapper {
        height: 43px;
        width: 144px;
    }

    &__access-type {
        margin-top: 24px;
    }

    &__selection {
        margin-top: 16px;
        display: flex;
    }

    &__selection-label {
        font-size: $text-size-s;
        &:hover {
            cursor: pointer;
        }
    }

    &__checkbox-wrapper {
        margin-top: -10px;
    }

    &__access-type-title {
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
    }

    &__error-message {
        padding-top: 5px;
        color: $c-red;
        font-size: $text-size-xs;
        margin-top: 4px;
    }
}
