.app-input-date {
    &__input-wrapper {
        height: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        position: relative;
    }

    &__input {
        height: 100%;
        width: 100%;
        padding-left: 16px;
        border: 1px solid $c-line-gray !important;
        border-radius: 6px;
        color: $c-dark-gray;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        &:focus {
            outline: none;
            border: 1px solid $c-blue !important;
        }

        &::placeholder {
            color: $c-lightest-gray;
            font-weight: $text-weight-light;
        }
    }

    &__title {
        position: absolute;
        right: 40px;
        pointer-events: none;
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;
        color: $c-lightest-gray;
    }

    &__calendar-icon {
        position: absolute;
        right: 16px;
    }

    &__input-error {
        border: 1px solid $c-red !important;
    }

    &__input-disabled {
        border: 1px solid $c-gray !important;
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
        margin-top: 4px;
    }
}

// calendar box
.css-dhopo2 {
    min-height: 234px !important;
}

.css-1ozefeb {
    z-index: 9999 !important;
}
