.page-payslip {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__button-wrapper {
        height: 38px;
        width: 140px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    &__bulk-upload {
        display: flex;
        flex-direction: row;
        height: 450px;

        .bulk-upload {
            &__upload-contents {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px;
                margin-bottom: 12px;
                margin-left: 80px;
                margin-right: 80px;
            }

            &__input {
                position: absolute;
                top: -999px;
                left: -999px;
                width: 0px;
                height: 0px;
                opacity: 0;
            }

            &__upload-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: white;
                width: 144px;
                height: 160px;
                border: 1px dashed $c-light-blue;
                border-radius: 6px;
                justify-content: space-evenly;
            }

            &__upload-title {
                color: $c-dark-gray;
                font-size: $text-size-s;
                font-weight: $text-weight-normal;
            }

            &__upload-subtitle {
                color: $c-dark-gray;
                font-size: $text-size-s;
                font-weight: $text-weight-medium;
            }

            &__button-wrapper {
                height: 32px;
                width: 102px;
                // pointer-events: none;
            }

            &__files-contents {
                background-color: $c-lightest-blue;
                width: 320px;
                border-bottom-right-radius: 6px;
            }

            &__files-contents-scroll {
                overflow-y: scroll;
                height: 370px;

                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #617082;
                    border-radius: 999px;
                }
            }

            &__files-contents-header {
                padding-top: 16px;
                padding-left: 24px;
                display: flex;
                flex-direction: column;
            }

            &__files-content-header-title {
                font-weight: $text-weight-medium;
                font-size: $text-size-xs;
                color: $c-blue-gray-darkest;
            }

            &__files-content-header-error {
                color: $c-red;
                font-weight: $text-weight-medium;
                font-size: $text-size-xxs;
            }

            &__files-wrapper {
                padding: 16px 27px;
            }

            &__files-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 16px;
            }

            &__files-upload-details {
                display: flex;
                flex-direction: row;
            }

            &__file-icon-wrapper {
                position: relative;
            }

            &__file-icon-name {
                position: absolute;
                top: 10px;
                font-size: 6px;
                width: 18px;
                display: flex;
                justify-content: center;
            }

            &__files-upload-contents {
                margin-left: 7px;
            }

            &__files-upload-title {
                color: $c-blue-gray-darkest;
                font-weight: $text-weight-normal;
                font-size: $text-size-xxs;
            }

            &__files-upload-size {
                color: $c-lightest-gray;
                font-weight: $text-weight-medium;
                font-size: $text-size-xxxs;
            }

            &__files-upload-logo-symbol {
                display: flex;
                flex-direction: row;
            }

            &__remove-icon {
                color: $c-red;
                margin-left: 8px;
            }

            &__files-button-row {
                display: flex;
                flex-direction: row;
                height: 70px;
                align-items: center;
                justify-content: flex-end;
                margin-right: 25px;
            }

            &__files-button-wrapper {
                height: 32px;
                width: 75px;
                margin-left: 8px;
            }
        }
    }

    &__confirm-upload {
        width: 625px;

        .confirm-upload {
            &__header {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                min-height: 25px;
            }

            &__error {
                flex: 3;
                color: $c-red;
                font-weight: $text-weight-medium;
                font-size: $text-size-s;
            }

            &__notify {
                flex: 1;
                position: relative;
            }

            &__checkbox {
                position: absolute;
                top: -10px;
            }

            &__notify-employee {
                position: absolute;
                margin-left: 25px;
                font-size: $text-size-s;
                color: $c-dark-gray;
                font-weight: $text-weight-normal;
            }

            &__react-table-wrapper {
                margin: 16px 0px 24px 0px;

                .react-table {
                    &__filenames-header {
                        margin-left: 24px;
                    }

                    &__filenames-data {
                        margin-left: 24px;
                        margin-right: 20px;
                    }

                    &__staff-id-data {
                        padding-left: 5px;
                    }

                    &__delete-icon {
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &__tooltip {
                        background-color: $c-white;
                        opacity: 1;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                    }

                    &__tooltip-error-message {
                        color: $c-red;
                    }
                }
            }

            &__files-button-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }

            &__files-button-wrapper {
                height: 32px;
                width: 75px;
                margin-left: 8px;
            }
        }
    }

    .react-table {
        &__staff-id {
            &-header {
                margin-left: 24px;
            }

            &-data {
                margin-left: 24px;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
