.app-pages-components-layout {
    &__header-components {
        margin-top: 24px;
        margin-bottom: 32px;
    }
    &__subheader-components {
        margin-bottom: 30px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
