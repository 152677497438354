.page-send-letter {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__contents {
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    }

    &__header {
        display: flex;
        flex-direction: row;
        padding: 9px 16px;
        background-color: $c-table-header;
        align-items: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border: 1px solid $c-line-gray;
    }

    &__header-username {
        flex: 1;
    }

    &__header-confirmation {
        flex: 3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__preview-button {
        height: 32px;
        width: 89px;
    }

    &__icon-eye {
        filter: invert(28%) sepia(55%) saturate(2052%) hue-rotate(194deg) brightness(107%) contrast(93%);
        width: 16px;
    }

    &__body {
        display: flex;
        flex-direction: row;
        border-left:  1px solid $c-line-gray;
        border-right: 1px solid $c-line-gray;
        border-bottom: 1px solid $c-line-gray;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &__username-column {
        flex: 1;
        border-bottom-left-radius: 6px;
    }

    &__letter-content {
        flex: 3;
        border-bottom-right-radius: 6px;
        border-left: 1px solid $c-line-gray;
    }

    &__dropdown-row {
        display: flex;
        justify-content: flex-end;
        margin: 16px 24px;
    }

    &__dropdown-wrapper {
        width: 304px;
        height: 38px;
    }

    &__input {
        border: none;
        &:focus {
            outline: none;
        }
        &--bold {
            font-weight: $text-weight-bold;
        }
    }

    &__username {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 24px;
        justify-content: space-between;
        cursor: pointer;
        &--active {
            background-color: $c-lightest-blue;
        }
    }

    &__close-icon {
        height: 12px;
        width: 12px;
        &:hover {
            cursor: pointer;
        }
    }

    &__save-button-row {
        display: flex;
        justify-content: flex-end;
    }

    &__save-button-wrapper {
        height: 32px;
        width: 126px;
        margin: 24px;
    }

    &__send-button-row {
        display: flex;
        justify-content: flex-end;
        margin: 24px 0px;
    }

    &__send-button-wrapper {
        height: 32px;
        width: 127px;
    }

    &__modal-attachment {
        width: 80vh;
        height: 95vh;
    }
}