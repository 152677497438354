// @mixin important-text {
//     font-size: 25px;
//     font-weight: bold;
//     border: 1px solid blue;
// }

.react-datepicker__navigation {
    top: 10px;
}

.react-datepicker__header {
    background-color: inherit;
    border: none;
    padding: 0px;
}

.react-datepicker__current-month {
    padding: 15px 0px;
    background-color: blue;
}

.outter-margin {
    margin: 10mm 20mm;
}

#pdf-class {
    background-color: yellow;
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    border: 1px #d3d3d3 solid;
    font-size: 14px;
}

.important-text {
    font-size: 25px;
    font-weight: bold;
    border: 1px solid green;
}

.hello {
    @extend .important-text;
    color: red;
}

.calendar-wrapper {
    // background-color: gray;
    width: 700px;
}

thead {
    margin-bottom: 10px;
}

tbody {
    margin-top: 10px;
}

// .fc {
//     &-prev-button {
//         background-color: yellow !important;
//     }

//     &-next-button {
//         background-color: green !important;
//     }

//     &-today-button {
//         // background-color: blue !important;
//     }

//     &__event {
//         background-color: $c-white;
//         white-space: pre-wrap;
//         padding: 2px 0 2px 0;
//     }

//     &__event--green {
//         background-color: $c-lightest-green;
//     }

//     &__event--blue {
//         background-color: $c-lightest-blue;
//     }

//     &__view {
//         background-color: $c-white;
//     }

//     &__day-header {
//         background-color: $c-white !important;
//         margin-bottom: 10px;
//     }

//     // calendar date
//     .fc-daygrid-day-top {
//         flex-direction: row;
//     }

//     &__event-title {
//         color: $c-black;
//         font-size: $text-size-xxs;
//         font-weight: $text-weight-normal;
//         margin-left: 5px;
//     }

//     &-event-main {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//     }

//     &-event-img {
//         display: flex;
//         width: 15px;
//         justify-content: center;
//         margin-left: 5px;
//         img {
//             height: 10px;
//         }
//     }

//     &-day-sat {
//         background-color: $c-blue-gray;
//     }

//     &-day-sun {
//         background-color: $c-blue-gray;
//     }
// }
