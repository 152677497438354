.app-page-header {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__button-row {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__table-title {
        margin-top: 32px;
        margin-bottom: 16px;
        color: $c-dark-gray;
        font-weight: $text-weight-medium;
        font-size: $text-size-body;
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
