.app-toggle {
    display: flex;
    &__switch {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 15px;
        margin: 0px;
        padding: 0px;
    }

    &__input {
        width: 0;
        height: 0;
        opacity: 0;
    }

    .app-toggle__input:checked + .app-toggle__slider {
        background-color: $c-blue;
        border-radius: 999px;
        border: 2px solid $c-blue;

        &:before {
            border: 2px solid $c-blue;
        }
    }

    .app-toggle__input:focus + .app-toggle__slider {
        box-shadow: 0 0 1px $c-blue;
        border-radius: 999px;
    }

    .app-toggle__input:checked + .app-toggle__slider:before {
        -webkit-transform: translateX(9px);
        -ms-transform: translateX(9px);
        transform: translateX(9px);
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: inherit;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        border-radius: 999px;
        border: 2px solid $c-lightest-gray;

        &:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: -2px;
            bottom: -2px;
            background-color: white;
            border: 2px solid $c-lightest-gray;
            -webkit-transition: 0.2s;
            transition: 0.2s;
            border-radius: 999px;
        }
    }
}
