.app-pages-layout {
    width: 900px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 80px;
    height: 100%;

    // @include min-width($scr-xlg) {
    //     width: 80%;
    // }
}
