.root-app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.root-page {
    position: relative;
    height: 100%;
    display: flex;
}

.root-main {
    width: 100%;
    &--height-deduct {
        height: calc(100% - 72px);
    }
}
