.app-paginate {
    display: flex;
    padding-left: 0;
    list-style: none;
    &__box-item {
        margin: 4px;
    }

    &__arrow {
        border-radius: 999px;
        border: 1px solid $c-blue-gray-bright;
        color: $c-blue-gray-bright;
        &-link {
            color: $c-dark-blue-gray !important;
            padding-top: 0px !important;
            font-weight: $text-weight-medium;
            text-decoration: none !important;
        }
    }

    .disabled {
        a {
            color: $c-blue-gray-bright !important;
            &:hover {
                cursor: default;
            }
        }
    }

    &__link-item {
        text-decoration: none;
        color: $c-dark-gray;
        font-size: $text-size-xxs;
        font-weight: $text-weight-normal;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;

        &:hover {
            // color: $c-dark-blue-gray !important;
            color: $c-dark-gray !important;
            text-decoration: none;
        }
    }

    &__active {
        background-color: $c-blue-gray-bright;
        border-radius: 9999px;
    }
}
