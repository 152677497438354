.app-notification {
    background-color: $c-white;
    width: 472px;
    right: 0px;
    position: absolute;
    height: 100%;
    border: none;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1) {
    }

    &__open {
        animation: expandNotification 0.5s ease forwards;
    }

    &__close {
        animation: shrinkNotification 0.5s ease forwards;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);

        &-open {
            // animation: fadeIn 0.1s ease forwards;
        }
        &-close {
            animation: fadeOut 0.5s ease forwards;
        }
    }

    &__contents {
        height: 100%;
        width: 100%;

        &-header {
            height: 72px;
            width: 100%;
            background-color: $c-light-blue;
            color: $c-white;
            display: flex;
            align-items: center;
            padding-left: 25px;
            padding-right: 25px;
            font-weight: $text-weight-normal;
            font-size: $text-size-xml;
            justify-content: space-between;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        }
    }

    &__icon-wrapper {
        &:hover {
            cursor: pointer;
        }
    }

    &__close-icon {
        height: 24px;
        width: 24px;
        &:hover {
            cursor: pointer;
        }
    }

    &__notification {
        &-scrolling {
            height: calc(100% - 72px) !important;
            overflow-y: scroll;
            position: relative;

            &::-webkit-scrollbar-track {
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar {
                width: 8px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.2);
                border-radius: 999px;
            }
        }

        &-read {
            background-color: inherit;
            position: absolute;
            right: 24px;
            top: 32px;
            border: none;
            font-weight: $text-weight-medium;
            font-size: $text-size-xs;
            color: $c-lightest-gray;
            &:hover {
                cursor: pointer;
            }
        }

        &-date {
            padding: 32px 24px 0px 24px;
            margin-bottom: 32px;
            color: $c-lightest-gray;
            font-weight: $text-weight-medium;
            font-size: $text-size-body;
        }

        &-item {
            padding: 8px 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            &:hover {
                background-color: $c-lightest-blue;
                cursor: pointer;
            }
        }

        &-dot {
            height: 12px;
            width: 12px;
            border-radius: 999px;
            background-color: $c-red;
            position: absolute;
            top: 8px;
            left: 24px;
        }

        &-image {
            height: 48px;
            width: 48px;
            border-radius: 999px;
            margin-left: 20px;
            margin-right: 17px;
        }

        &-details {
            width: 225px;
            font-weight: $text-weight-normal;
            color: $c-blue-gray-darkest;
            font-size: $text-size-s;
        }

        &-time {
            position: absolute;
            right: 24px;
            color: $c-lightest-gray;
            font-size: $text-size-xs;
        }

        &-highlight {
            font-weight: $text-weight-medium;
        }
    }

    &__message {
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        &--bold {
            font-weight: $text-weight-medium;
        }
    }


    &__footer {
        padding-bottom: 45px;
        padding-top: 45px;
        display: flex;
        justify-content: center;
        color: $c-blue-gray;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
    }
}

@keyframes expandNotification {
    0% {
        margin-right: -472px;
    }
    100% {
        margin-right: 0px;
    }
}

@keyframes shrinkNotification {
    0% {
        margin-right: 0px;
    }
    100% {
        margin-right: -472px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
