.Toastify {
    z-index: 99999 !important;
    &__toast {
        min-height: 48px;
        min-width: 550px;
        box-shadow: none;
        border-radius: 8px;
        @include max-width($scr-xs) {
            width: 400px;
        }
    }

    &__toast-container {
        width: auto !important;
    }

    &__close-button {
        align-self: center;
        > svg {
            height: 18px;
            width: 18px;
        }
    }
}

.app-layout {
    &__pages-wrapper {
        min-height: 100%;
        display: flex;
        justify-content: center;
    }

    &--min-width {
        min-width: 940px;
    }

    &__scrollbar {
        .rc-scrollbars-track {
            z-index: 0 !important;
        }
    }
}
