.page-benefits-position {
    &__confirm-benefit {
        width: 304px;
        .confirm-benefit {
            &__title {
                color: $c-lightest-gray;
                font-size: $text-size-xs;
            }
            &__display-wrapper {
                margin-top: 16px;
                margin-bottom: 16px;
            }
            &__button {
                &-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 24px;
                }
                &-wrapper {
                    height: 43px;
                    width: 144px;
                }
            }
        }
    }
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__button-wrapper {
        height: 38px;
        width: 140px;
    }

    &__table-wrapper {
        margin-top: 24px;
    }

    .react-table {
        &__position {
            &-header {
                margin-left: 24px;
            }

            &-data {
                margin-left: 24px;
            }
        }

        &__edit-icon {
            height: 16px;
            width: 16px;
        }

        &__arrow-icon {
            height: 8px;
            width: 7px;
            margin-left: 12.5px;
            display: none;

            &-down {
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            &--active {
                filter: opacity(1) !important;
                display: inline-block;
            }
        }

        &__header {
            &:hover {
                cursor: pointer;
                .react-table__arrow-icon {
                    display: inline-block;
                    filter: opacity(0.5);
                }
            }
        }
    }

    &__pagination {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__disclaimer {
        width: 350px;

        .disclaimer {

            &__button-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 20px;
            }

            &__button-wrapper {
                height: 38px;
                width: 80px;
                margin-left: 8px;
            }
        }
    }
}

.create-position {
    width: 304px;
    &__input-wrapper {
        margin-bottom: 16px;
    }

    &__button {
        &-row {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
        }

        &-wrapper {
            height: 43px;
            width: 144px;
        }
    }
}
