.page-dashboard {
    &__header {
        margin-top: 24px;
        margin-bottom: 30px;
    }

    &__header-welcome {
        color: $c-blue;
        font-size: $text-size-m;
        font-weight: $text-weight-medium;
    }

    &__header-datetime {
        color: $c-lightest-gray;
        font-weight: $text-weight-medium;
        font-size: $text-size-s;
    }

    &__contents {
        display: flex;
        flex-direction: row;
        margin: -8px;
    }

    &__column-1 {
        flex: 7;
    }

    &__column-2 {
        flex: 3;
        display: flex;
        flex-direction: column;
    }

    &__info-row {
        display: flex;
        flex-direction: row;
    }

    &__card-styling {
        position: relative;
        margin: 8px;
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        padding: 16px;

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #617082;
            border-radius: 999px;
        }
    }

    &__card-contents-title {
        color: $c-dark-gray;
        font-size: $text-size-body;
        font-weight: $text-weight-normal;
    }

    &__arrow-right {
        height: 9.5px;
        margin-left: 7px;
        filter: invert(35%) sepia(87%) saturate(1001%) hue-rotate(187deg) brightness(88%) contrast(102%);
    }

    &__tab {
        display: none;
        &--selected {
            display: block;
            height: 100%;
        }
        &--unselected {
            display: none;
        }
    }

    .employee-graph-card {
        height: 100%;
        &__row {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: flex-end;
            height: calc(100% - 24px);
        }

        &__column {
            width: 50%;
        }

        &__numbers-row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__numbers {
            color: $c-dark-gray;
            font-weight: $text-weight-medium;
            font-size: $text-size-xxxl;
        }

        &__percentage-increased {
            color: $c-light-green;
            font-weight: $text-weight-medium;
            font-size: $text-size-s;
            margin-left: 8px;
        }

        &__percentage-decrease {
            color: $c-red !important;
        }

        &__arrow-green {
            margin-left: 4.5px;
            filter: invert(82%) sepia(11%) saturate(1139%) hue-rotate(88deg) brightness(95%) contrast(82%);
        }

        &__arrow-red {
            filter: invert(37%) sepia(44%) saturate(3552%) hue-rotate(336deg) brightness(96%) contrast(80%);
            transform: rotate(180deg);
        }

        &__time-period {
            color: $c-lightest-gray;
            font-size: $text-size-s;
            font-weight: $text-weight-normal;
        }
    }

    .employee-on-leave {
        height: 100%;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__view-all-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $c-blue;
            font-size: $text-size-xxs;
            font-weight: $text-weight-normal;
            background-color: inherit;
            border: none;
        }

        &__view-all {
            padding-top: 1px;
        }

        &__body {
            margin-top: 14px;
        }

        &__empty-card {
            height: 80px;
        }
    }

    .announcement {
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__header-button-wrapper {
            width: 180px;
            height: 32px;

            img {
                filter: invert(29%) sepia(74%) saturate(3955%) hue-rotate(204deg) brightness(94%) contrast(80%);
                width: 16px;
            }
        }

        &__tab-header-row {
            list-style-type: none;
            margin: 16px 0 0 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $c-line-gray;
            font-size: $text-size-s;
            font-weight: $text-weight-normal;
            height: 35px;
            display: flex;
            align-items: center;
        }

        &__tab-selection {
            width: 144px;
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            margin-right: 16px;
            color: $c-lightest-gray;

            &:focus {
                outline: none;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &__tab-selected {
            background-color: $c-amber;
            color: $c-dark-gray;
            font-weight: $text-weight-medium;
        }
        
        &__tab-empty {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $c-lightest-gray;
        }

        &__contents {
            height: 224px;
            overflow-y: scroll;

            &::-webkit-scrollbar-track {
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar {
                width: 4px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background: #617082;
                border-radius: 999px;
            }
        }

        &__contents-row-wrapper {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $c-blue-gray;

            &:last-child {
                border-bottom: none;
            }
        }

        &__contents-text-column {
            flex: 4;
        }

        &__contents-title {
            color: $c-blue;
            font-weight: $text-weight-medium;
            font-size: $text-size-xs;
            padding-top: 16px;
        }

        &__contents-details {
            color: $c-dark-gray;
            font-weight: $text-weight-normal;
            font-size: $text-size-xs;
            padding-top: 8px;
            padding-bottom: 16px;
        }

        &__contents-button-column {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-right: 16px;
        }

        &__delete-icon {
            height: 15px;
        }

        &__contents-button {
            border: none;
            background-color: inherit;
            padding: 0;
            margin: 0 0 0 18px;
            height: 15px;
        }

        &__pdf-file {
            height: 24px;
            width: 18px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .celebration-corner {
        display: flex;
        flex-direction: column;

        &__contents {
            margin-top: 12px;
            overflow-y: scroll;
            position: relative;

            &::-webkit-scrollbar-track {
                margin-bottom: 4px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar {
                width: 4px;
                position: absolute;
                right: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background: #617082;
                border-radius: 999px;
                position: absolute;
                right: 0px;
            }
        }

        &__empty-card {
            height: 50px;
        }
    }

    .total-employee {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;

        &__column-1 {
            display: flex;
            flex-direction: column;
            flex: 3;
        }

        &__column-2 {
            display: flex;
            flex-direction: column;
            flex: 2;
            align-items: flex-end;
            justify-content: space-between;
        }

        &__label-text {
            font-size: $text-size-xxs;
            font-weight: $text-weight-normal;
        }

        &__total-value {
            color: $c-dark-gray;
            font-weight: $text-weight-medium;
            font-size: $text-size-xxxl;
        }

        &__legend {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__legend-color {
            width: 12px;
            height: 12px;
            border-radius: 999px;
        }

        &__legend-details {
            font-size: $text-size-xs;
            margin-left: 4px;
            font-weight: $text-weight-normal;
        }
    }

    .holiday-this-month {
        &__contents {
            margin-top: 14px;
        }

        &__empty-card {
            height: 50px;
        }
    }

    .pending {
        &__pending-button {
            background-color: inherit;
            border: none;
            font-weight: $text-weight-normal;
            color: $c-blue;
            font-size: $text-size-xxs;
            margin: 8px 0px;
        }

        &__pending-value {
            font-size: $text-size-xxl;
            font-weight: $text-weight-medium;
        }

        &__contents {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 8px;
            border-bottom: 1px solid $c-blue-gray;
            &:first-child {
                padding-top: 0px;
                margin-bottom: 8px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__empty-card {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $text-size-s;
        font-weight: $text-weight-normal;
        color: $c-lightest-gray;
    }

    &__create-announcement {
        &-pin {
            &:hover {
                cursor: pointer;
            }
        }
        width: 624px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .create-announcement {
            &__row-1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            &__row-2 {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 8px;
            }

            &__column-1 {
                width: 304px;
                display: flex;
                flex-direction: column;
            }

            &__column-2 {
                width: 304px;
                display: flex;
                flex-direction: column;
            }

            &__input-wrapper {
                margin-bottom: 16px;
            }

            &__button-wrapper {
                width: 144px;
                height: 43px;
                margin-left: 16px;
            }
        }
    }
}

.list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;

    &__details-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__details {
        color: $c-dark-gray;
        font-weight: $text-weight-normal;
        font-size: $text-size-xs;
    }

    &__details-img {
        height: 24px;
        width: 24px;
        border-radius: 999px;
    }

    &__info {
        font-weight: $text-weight-medium;
        font-size: $text-size-xxs;

        &--red {
            color: $c-red;
        }

        &--blue {
            color: $c-blue;
        }
    }
}

.graph-tooltip {
    background-color: $c-white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 8px;
    font-size: $text-size-xxs;
    color: $c-dark-gray;
}
