.app-side-nav {
    width: 392px;
    overflow: auto;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
        display: none;
    }

    &--shrink {
        animation: shrinkNav 0.5s ease forwards;
    }

    &--expand {
        @include min-width($scr-xs) {
            animation: expandNav 0.5s ease forwards;
        }
        @include max-width($scr-xs) {
            animation: expandNavSmall 0.5s ease forwards;
        }
    }

    &--hide {
        li {
            p {
                animation: hideItem 0.5s ease forwards;
            }
        }
    }

    &--show {
        li {
            p {
                animation: showItem 0.5s ease forwards;
            }
        }
    }

    &__logo-wrapper {
        width: 100%;
        padding-top: 24px;
        padding-left: 33px;
    }

    &__list {
        margin-top: 46px;
        list-style-type: none;
        padding: 0px;
    }

    &__item {
        display: flex;
        height: 58px;
        &:hover {
            cursor: pointer;
        }
    }

    &__item--expand {
        &--active {
            background-color: $c-lightest-blue;
        }
        &--hover {
            &:hover {
                background-color: $c-lightest-blue;
            }
        }
    }

    &__item--shrink {
        &--active {
            .app-side-nav__item-icon {
                filter: invert(59%) sepia(39%) saturate(5154%) hue-rotate(193deg) brightness(103%) contrast(93%);
            }
        }
        &--hover {
            &:hover {
                .app-side-nav__item-icon {
                    filter: invert(59%) sepia(39%) saturate(5154%) hue-rotate(193deg) brightness(103%) contrast(93%);
                }
            }
        }
    }

    &__item-icon {
        width: 24px;
        margin-left: 33px;
    }

    &__item-label {
        margin-left: 16px;
        display: flex;
        align-items: center;
        font-weight: $text-weight-normal;
        font-size: $text-size-body;
        opacity: 1;
        white-space: nowrap;
        height: 100%;
    }

    &__action {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 35px 30px;
        &:hover {
            cursor: pointer;
        }
    }

    &__action-icon {
        height: 20px;
        width: 11px;
    }

    &__link {
        color: $c-dark-gray !important;
        text-decoration: none !important;
    }
}

@keyframes shrinkNav {
    0% {
        width: 392px;
    }
    100% {
        width: 88px;
    }
}

@keyframes expandNav {
    0% {
        width: 88px;
    }
    100% {
        width: 392px;
    }
}

@keyframes expandNavSmall {
    0% {
        width: 88px;
    }
    100% {
        width: 542px;
    }
}

@keyframes hideItem {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes showItem {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
