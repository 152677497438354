.app-input {
    display: flex;
    flex-direction: column;

    &__wrapper {
        display: flex;
        position: relative;
        align-items: center;
    }

    &__left-icon {
        position: absolute;
        height: 16px;
        width: 16px;
        margin-left: 16px;
    }

    &__right-icon {
        position: absolute;
        height: 16px;
        width: 16px;
        right: 12px;
    }

    &--cursor-pointer {
        &:hover {
            cursor: pointer;
        }
    }

    &__input {
        font-weight: $text-weight-normal;
        font-size: $text-size-s;
        border-radius: 6px;
        padding: 0px 16px 0px 16px;
        border: 1px solid $c-line-gray;
        color: $c-dark-gray;
        height: 38px;
        width: 100%;

        &-password-placeholder {
            &::placeholder {
                color: $c-lightest-gray;
                font-weight: $text-weight-light;
                font-size: $text-size-s;
                font-family: "Roboto", sans-serif;
                letter-spacing: normal !important;
            }
        }

        &::placeholder {
            color: $c-lightest-gray;
            font-weight: $text-weight-light;
            font-size: $text-size-s;
            font-family: "Roboto", sans-serif;
            // letter-spacing: normal !important;
        }

        &:focus {
            outline: none;
            border: 1px solid $c-blue;
        }

        &::-ms-reveal {
            display: none;
        }
    }

    &--font-weight-normal {
        &::placeholder {
            font-weight: $text-weight-normal !important;
        }
    }

    &--padding-left {
        padding-left: 48px !important;
    }

    &--padding-right {
        padding-right: 48px;
    }

    &__input[type="password"] {
        color: $c-lightest-gray;
        // letter-spacing: 2px;
        // font-size: 24px;
        // transform: translate3d(0, -2.5px, 0);
    }

    &__eye-icon {
        height: 15px;
        width: 15px;
        object-fit: contain;
    }

    &__eye-button {
        background-color: inherit;
        position: absolute;
        right: 10px;
        border: none;
        display: flex;

        &:hover {
            cursor: pointer;
        }
    }

    &__error {
        color: $c-red;
        border: 1px solid $c-red;
        &:focus {
            outline: none;
            border: 1px solid $c-red;
        }
    }

    &__error-message {
        color: $c-red;
        font-size: $text-size-xs;
        margin-top: 4px;
    }
}
