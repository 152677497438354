.page-claim-pending-payment {
    &__breadcrumb {
        margin-top: 24px;
        margin-bottom: 32px;
    }
    &__tab-wrapper {
        margin-bottom: 24px;
    }

    &__searchBar-wrapper {
        width: 300px;
    }

    &__search-content {
        border: 1px solid $c-blue-gray;
        border-radius: 6px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }

    &__input {
        width: 624px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &-wrapper {
            width: 304px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    &__table-wrapper {
        margin-top: 24px;

        .react-table {
            &__userName-header {
                margin-left: 25px;
            }
            &__userName-data {
                margin-left: 25px;
            }

            &__header {
                &:hover {
                    cursor: pointer;
                    .react-table__arrow-icon {
                        display: inline-block;
                        filter: opacity(0.5);
                    }
                }
            }

            &__arrow-icon {
                height: 8px;
                width: 7px;
                margin-left: 12.5px;
                display: none;

                &-down {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
                &--active {
                    filter: opacity(1) !important;
                    display: inline-block;
                }
            }

            &__amount-row {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-right: 60px;
            }
        }
    }

    &__button {
        &-row {
            display: flex;
            flex-direction: row;
        }
        &-wrapper {
            height: 32px;
            width: 66px;
            margin-left: 8px;
        }
    }

    &__paginate-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    &__modal {
        width: 304px;

        &-subtitle {
            color: $c-lightest-gray;
            font-weight: $text-weight-medium;
            font-size: $text-size-xs;
        }

        &-input {
            margin-top: 16px;
        }

        &-button-row {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
        }

        &-button {
            height: 43px;
            width: 144px;
        }
    }
}
