@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "styles/variables.scss";

// Generic
@import "styles/main.scss";
@import "styles/mixin.scss";

// Component
@import "styles/components/app-layout.scss";
@import "styles/components/app-side-nav.scss";
@import "styles/components/app-button.scss";
@import "styles/components/app-react-table.scss";
@import "styles/components/app-input.scss";
@import "styles/components/app-auth-layout.scss";
@import "styles/components/app-toast.scss";
@import "styles/components/app-header-bar.scss";
@import "styles/components/app-notification.scss";
@import "styles/components/app-breadcrumb.scss";
@import "styles/components/app-checkbox.scss";
@import "styles/components/app-paginate.scss";
@import "styles/components/app-table-empty.scss";
@import "styles/components/app-pages-layout.scss";
@import "styles/components/app-image-upload.scss";
@import "styles/components/app-input-date.scss";
@import "styles/components/app-input-date-range.scss";
@import "styles/components/app-dropdown.scss";
@import "styles/components/app-status.scss";
@import "styles/components/app-text-area.scss";
@import "styles/components/app-input-with-label.scss";
@import "styles/components/app-modal.scss";
@import "styles/components/app-modal-confirmation.scss";
@import "styles/components/app-tab-link.scss";
@import "styles/components/app-dropdown-multi-select.scss";
@import "styles/components/app-mega-dropdown.scss";
@import "styles/components/app-toggle.scss";
@import "styles/components/app-input-number.scss";
@import "styles/components/app-input-display.scss";
@import "styles/components/app-dropdown-checkbox.scss";
@import "styles/components/app-dropdown-mini.scss";
@import "styles/components/app-pages-components-layout.scss";
@import "styles/components/app-dual-columns-modal.scss";
@import "styles/components/app-time-picker.scss";
@import "styles/components/app-input-upload-attachment.scss";
@import "styles/components/app-page-header.scss";
@import "styles/components/app-attachment-image.scss";

// Page
@import "styles/pages/authentication/login.scss";
@import "styles/pages/authentication/reset-password.scss";
@import "styles/pages/authentication/reset-password-check-email.scss";
@import "styles/pages/authentication/new-password.scss";

@import "styles/pages/home/dashboard.scss";
@import "styles/pages/home/employee-dashboard.scss";

@import "styles/pages/company-benefits/position.scss";
@import "styles/pages/company-benefits/benefit.scss";
@import "styles/pages/company-benefits/create-edit-benefit-package.scss";

@import "styles/pages/leave-management/leave-calendar.scss";
@import "styles/pages/leave-management/leave-directory.scss";
@import "styles/pages/leave-management/leave-history.scss";
@import "styles/pages/leave-management/leave-summary.scss";
@import "styles/pages/leave-management/leave-pending-approval.scss";
@import "styles/pages/leave-management/pending-leave.scss";
@import "styles/pages/leave-management/new-leave.scss";

@import "styles/pages/claim-management/claim-directory.scss";
@import "styles/pages/claim-management/claim-pending-approval.scss";
@import "styles/pages/claim-management/claim-pending-payment.scss";
@import "styles/pages/claim-management/claim-summary.scss";
@import "styles/pages/claim-management/claim-history.scss";
@import "styles/pages/claim-management/claim-summary.scss";
@import "styles/pages/claim-management/pending-claim.scss";
@import "styles/pages/claim-management/new-claim.scss";

@import "styles/pages/timesheet-management/timesheet-management.scss";

@import "styles/pages/payslip/payslip.scss";
@import "styles/pages/payslip/employee-payslip.scss";

@import "styles/pages/employee-confirmation/employee-confirmation.scss";
@import "styles/pages/employee-confirmation/send-letter.scss";

@import "styles/pages/coaching-session/upcoming-schedule.scss";
@import "styles/pages/coaching-session/coaching-history.scss";
@import "styles/pages/coaching-session/create-coaching-session.scss";

@import "styles/pages/user-management/user-management.scss";
@import "styles/pages/user-management/employee.scss";
@import "styles/pages/user-management/employee-details.scss";

@import "styles/pages/page404.scss";

* {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
    height: 100%;

    body {
        padding: 0;
        margin: 0;
        min-height: 100%;
        white-space: pre-wrap;
        font-family: "Roboto", sans-serif;
    }
}
